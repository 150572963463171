import {
  GET_PROFESSIONAL,
  DELETE_PROFESSIONAL,
  GET_SINGLE_PROFESSIONAL,
  UPDATE_PROFESSIONAL,
} from "../actions/types";

const INITIAL_STATE = {
  professionals: [],
  professional: {},
  loading: true,
  pagination: [],
};

export default function professionalReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_PROFESSIONAL:
      return {
        ...state,
        loading: false,
        professionals: action.payload.professionals,
        pagination: action.payload.pagination,
      };

    case GET_SINGLE_PROFESSIONAL:
      return {
        ...state,
        loading: false,
        professional: action.payload.professional,
        // professional: {
        //   ...state.professionals.find(
        //     (act) => act.professionalId === action.payload
        //   ),
        // },
      };

    case DELETE_PROFESSIONAL:
      return {
        ...state,
        loading: false,
        professionals: state.professionals.filter(
          (act) => act.professionalId !== action.payload
        ),
      };

    case UPDATE_PROFESSIONAL:
      console.log(action.payload);
      return {
        ...state,
        professional: action.payload,
        loading: false,
      };

    default:
      return state;
  }
}
