import {
    GET_BUSINESS_TYPE,
    DELETE_BUSINESS_TYPE,
    GET_SINGLE_BUSINESS_TYPE,
    UPDATE_BUSINESS_TYPE,
  } from "../actions/types";
import _,{ sortBy } from "lodash";
  
  const INITIAL_STATE = {
    businessTypes: [],
    businessType: {},
    loading: true,
    pagination: []
  };
  
  export default function businessReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
      case GET_BUSINESS_TYPE:
        return {
          ...state,
          loading: false,
          businessTypes: _.sortBy(action.payload.businessCategories, [business => business.title.toLowerCase()]),
          pagination: action.payload.pagination
        };
  
      case GET_SINGLE_BUSINESS_TYPE:
        return {
          ...state,
          loading: false,
          businessType: {
            ...state.businessTypes.find(
              (act) => act.businessCategoryId === action.payload
            ),
          },
        };
  
      case DELETE_BUSINESS_TYPE:
        return {
          ...state,
          loading: false,
          businessTypes: state.businessTypes.filter(
            (act) => act.businessCategoryId !== action.payload
          ),
        };
  
        case UPDATE_BUSINESS_TYPE:
          console.log(action.payload)
        return {
          ...state,
          certificateType: action.payload,
          loading: false,
        };
  
    
  
      default:
        return state;
    }
  }
  