import { axiosInstance } from "./interceptor";

export const getAllFeatureUser = async (url) => {
  try {
    return await axiosInstance.get(url);
  } catch (error) {
    return error.response;
  }
};
export const addFeaturedProfessional = async (url, data) => {
  try {
    return await axiosInstance.put(url, data);
  } catch (error) {
    return error.response;
  }
};
export const editFeaturedProfessional = async (url, data) => {
  try {
    return await axiosInstance.patch(url, data);
  } catch (error) {
    return error.response;
  }
};

export const changePriority = async (url, data) => {
  try {
    return await axiosInstance.patch(url, data);
  } catch (error) {
    return error.response;
  }
};
// export const getAHashtag = async (url) => {
//   try {
//     return await axiosInstance.get(url);
//   } catch (error) {
//     return error.response;
//   }
// };
export const deleteFeaturedProfessional = async (url) => {
  try {
    return await axiosInstance.delete(url);
  } catch (error) {
    return error.response;
  }
};
