import {
  ADMIN_LOGIN_REQUEST,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGIN_FAIL,
  ADMIN_LOGOUT,
  SET_INITIAL_USER,
  VERIFY_EMAIL,
} from "../actions/types";
import jsCookie from "js-cookie";

const INITIAL_STATE = {
  user: {},
  isAuthenticated: false,
  loading: true,
  message: null,
  token: null,
  stripeToken: null,
};

export default function AdminLoginReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADMIN_LOGIN_SUCCESS:
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("admin", JSON.stringify(action.payload.user));
      localStorage.setItem("adminid", action.payload.user.userId);
      console.log(action.payload.user.globalSettings.stripePublicKey);
      localStorage.setItem(
        "stripeToken",
        action.payload.user.globalSettings.stripePublicKey
      );
      return {
        ...state,
        loading: false,
        isAuthenticated: action.payload.token ? true : false,
        message: action.payload.message,
        token: action.payload.token,
        stripeToken: action.payload.user.globalSettings.stripePublicKey,
        // user: { ...action.payload.user },
      };
    case ADMIN_LOGIN_FAIL:
      localStorage.removeItem("token");
      return {
        loading: true,
        isAuthenticated: false,
        token: null,
        message: "Login Failed, Try again later",
      };
    case SET_INITIAL_USER:
      // console.log(action, "dwdjhv");
      return {
        ...state,
        user: { ...action.payload.user },
        isAuthenticated: action.payload.token ? true : false,
      };
    case ADMIN_LOGOUT:
      localStorage.removeItem("stripeToken");
      return {
        isAuthenticated: false,
        user: {},
        loading: true,
      };
    default:
      return state;
  }
}
