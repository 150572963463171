import {
  GET_OWNER_DOCUMENTATION_TYPE,
  DELETE_OWNER_DOCUMENTATION_TYPE,
  GET_SINGLE_OWNER_DOCUMENTATION_TYPE,
  UPDATE_OWNER_DOCUMENTATION_TYPE,
} from "../actions/types";

const INITIAL_STATE = {
  ownerDocumentationTypes: [],
  ownerDocumentationType: {},
  loading: true,
  pagination: [],
};

export default function ownerDocumentationTypeReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_OWNER_DOCUMENTATION_TYPE:
      return {
        ...state,
        loading: false,
        ownerDocumentationTypes: action.payload.ownerDocumentationTypes,
        pagination: action.payload.pagination,
      };

    case GET_SINGLE_OWNER_DOCUMENTATION_TYPE:
      return {
        ...state,
        loading: false,
        ownerDocumentationType: {
          ...state.ownerDocumentationTypes.find(
            (act) => act.ownerDocumentationTypeId === action.payload
          ),
        },
      };

    case DELETE_OWNER_DOCUMENTATION_TYPE:
      return {
        ...state,
        loading: false,
        ownerDocumentationTypes: state.ownerDocumentationTypes.filter(
          (act) => act.ownerDocumentationTypeId !== action.payload
        ),
      };

    case UPDATE_OWNER_DOCUMENTATION_TYPE:
      console.log(action.payload);
      return {
        ...state,
        ownerDocumentationType: action.payload,
        loading: false,
      };

    default:
      return state;
  }
}
