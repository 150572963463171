import {
  GET_PENDING_CERTIFICATES,
  ACCEPT_CERTIFICATE,
  GET_CERTIFICATES_TYPE,
  DELETE_CERTIFICATES_TYPE,
  GET_SINGLE_CERTIFICATES_TYPE,
  UPDATE_CERTIFICATE_TYPE,
} from "../actions/types";

const INITIAL_STATE = {
  pending_certificates: [],
  certificateTypes: [],
  certificateType: {},
  certificate: {},
  loading: true,
  pagination: []
};

export default function certificateReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_PENDING_CERTIFICATES:
      return {
        ...state,
        loading: false,
        pending_certificates: action.payload,
      };

    case GET_CERTIFICATES_TYPE:
      return {
        ...state,
        loading: false,
        certificateTypes: action.payload.certificateCategories,
        pagination: action.payload.pagination
      };

    case GET_SINGLE_CERTIFICATES_TYPE:
      return {
        ...state,
        loading: false,
        certificateType: {
          ...state.certificateTypes.find(
            (act) => act.certificateCategoryId === action.payload
          ),
        },
      };

    case DELETE_CERTIFICATES_TYPE:
      return {
        ...state,
        loading: false,
        certificateTypes: state.certificateTypes.filter(
          (act) => act.certificateCategoryId !== action.payload
        ),
      };

      case UPDATE_CERTIFICATE_TYPE:
        console.log(action.payload)
      return {
        ...state,
        certificateType: action.payload,
        loading: false,
      };

    case ACCEPT_CERTIFICATE:
      return {
        ...state,
        pending_certificates: state.pending_certificates.filter(
          (act) => act.id !== action.payload.id
        ),
        loading: false,
      };

    default:
      return state;
  }
}
