import {
  GET_POST_CATEGORY,
  DELETE_POST_CATEGORY,
  GET_SINGLE_POST_CATEGORY,
  UPDATE_POST_CATEGORY,
} from "../actions/types";

const INITIAL_STATE = {
  postCategories: [],
  postCategory: {},
  loading: true,
  pagination: [],
};

export default function postReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_POST_CATEGORY:
      return {
        ...state,
        loading: false,
        postCategories: action.payload.postCategories,
        pagination: action.payload.pagination,
      };

    case GET_SINGLE_POST_CATEGORY:
      return {
        ...state,
        loading: false,
        postCategory: {
          ...state.postCategories.find(
            (act) => act.postCategoryId === action.payload
          ),
        },
      };

    case DELETE_POST_CATEGORY:
      return {
        ...state,
        loading: false,
        postCategories: state.postCategories.filter(
          (act) => act.postCategoryId !== action.payload
        ),
      };

    case UPDATE_POST_CATEGORY:
      console.log(action.payload);
      return {
        ...state,
        postCategory: action.payload,
        loading: false,
      };

    default:
      return state;
  }
}
