import {
  GET_SERVICE_CATEGORY,
  DELETE_SERVICE_CATEGORY,
  GET_SINGLE_SERVICE_CATEGORY,
  UPDATE_SERVICE_CATEGORY,
} from "../actions/types";

const INITIAL_STATE = {
  serviceCategories: [],
  serviceCategory: {},
  loading: true,
  pagination: [],
};

export default function serviceReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_SERVICE_CATEGORY:
      return {
        ...state,
        loading: false,
        serviceCategories: action.payload.serviceCategories,
        pagination: action.payload.pagination,
      };

    case GET_SINGLE_SERVICE_CATEGORY:
      return {
        ...state,
        loading: false,
        serviceCategory: {
          ...state.serviceCategories.find(
            (act) => act.serviceCategoryId === action.payload
          ),
        },
      };

    case DELETE_SERVICE_CATEGORY:
      return {
        ...state,
        loading: false,
        serviceCategories: state.serviceCategories.filter(
          (act) => act.serviceCategoryId !== action.payload
        ),
      };

    case UPDATE_SERVICE_CATEGORY:
      console.log(action.payload);
      return {
        ...state,
        serviceCategory: action.payload,
        loading: false,
      };

    default:
      return state;
  }
}
