import React, { Component } from "react";
import { logOut } from "../../../../utils/auth";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: false,
      setLoading: false,
      name: "",
    };
  }
  async componentDidMount() {
    let checkTokenData = localStorage.getItem("admin");
    let checkToken = JSON.parse(checkTokenData);
    // console.log(checkToken.firstName,'test')
    this.setState({
      name: checkToken.firstName + " " + checkToken.lastName,
    });
  }
  onLogout = (e) => {
    e.preventDefault();
    this.setState({
      setLoading: true,
    });
    logOut(`auth/logout`, localStorage.getItem("token"));
  };
  render() {
    return (
      <>
        {this.state.setLoading === true ? (
          <div className="loader-panel">
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
            </div>
            Loading...
          </div>
        ) : null}
        <header>
          <a href="#" className="block__mb mblogo">
            <img src="images/logo.png" alt="" />
          </a>
          <div className="leftPrt">
            <a className="sidetogglebtn">
              {/* <img
                src={`${process.env.PUBLIC_URL}/images/icon/toogle-icon.png`}
                alt=""
                className="hidden__mb"
              /> */}

              <img
                src={`${process.env.PUBLIC_URL}/images/icon/toogle.png`}
                alt=""
                className="block__mb"
              />
            </a>
            {/* <div className="searchprt hidden__mb">
              <input type="text" placeholder="Search..." />
            </div> */}
          </div>

          <div className="rightnotificationprt hidden__mb">
            <div className="bell">
              <a href="#">
                {/* <i class="fa fa-bell-o"></i> */}
                {/* <img
                  src={`${process.env.PUBLIC_URL}/images/icon/bell.png`}
                  alt=""
                /> */}
                {/* <span>3</span> */}
              </a>
              <div className="notification_drop">
                <h2>
                  Notification
                  <span>40</span>
                </h2>
                <ul className="mCustomScrollbar">
                  <li>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/profile_img.png`}
                      alt=""
                    />
                    <div className="rightnoteinfo">
                      <h3>#1872 has completed delivery</h3>
                      <div className="flex justify-spacebetwwen">
                        <p>Express delivery</p>
                        <small>23 feb, 2021</small>
                      </div>
                    </div>
                  </li>
                  <li>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/profile_img.png`}
                      alt=""
                    />
                    <div className="rightnoteinfo">
                      <h3>#1872 has completed delivery</h3>
                      <div className="flex justify-spacebetwwen">
                        <p>Express delivery</p>
                        <small>23 feb, 2021</small>
                      </div>
                    </div>
                  </li>
                  <li>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/profile_img.png`}
                      alt=""
                    />
                    <div className="rightnoteinfo">
                      <h3>#1872 has completed delivery</h3>
                      <div className="flex justify-spacebetwwen">
                        <p>Express delivery</p>
                        <small>23 feb, 2021</small>
                      </div>
                    </div>
                  </li>
                  <li>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/profile_img.png`}
                      alt=""
                    />
                    <div className="rightnoteinfo">
                      <h3>#1872 has completed delivery</h3>
                      <div className="flex justify-spacebetwwen">
                        <p>Express delivery</p>
                        <small>23 feb, 2021</small>
                      </div>
                    </div>
                  </li>
                  <li>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/profile_img.png`}
                      alt=""
                    />
                    <div className="rightnoteinfo">
                      <h3>#1872 has completed delivery</h3>
                      <div className="flex justify-spacebetwwen">
                        <p>Express delivery</p>
                        <small>23 feb, 2021</small>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="profile__dropdown">
              <a href="">
                <img
                  className="hdr-profile-img"
                  src={`${process.env.PUBLIC_URL}/images/profile_img.png`}
                  alt=""
                />
                {this.state.name}
              </a>
              <div className="dropdown-submenu listingMenu">
                <ul>
                  {/* <li>
                    <a href="">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/icon/edit.png`}
                        alt=""
                      />
                      Edit Profile
                    </a>
                  </li> */}

                  {/* <li>
                    <a href="">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/icon/faq.png`}
                        alt=""
                      />
                      Faq’s
                    </a>
                  </li> */}
                  <li>
                    <a
                      href="#"
                      onClick={
                        this.state.setLoading === true
                          ? (e) => {
                              e.preventDefault();
                            }
                          : this.onLogout
                      }
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/images/icon/logout.png`}
                        alt=""
                      />{" "}
                      Log out
                    </a>
                  </li>
                  {/* <li>
                    <a href="">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/icon/settings.png`}
                        alt=""
                      />
                      Settings
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </header>
      </>
    );
  }
}
export default Header;
