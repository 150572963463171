import {
  GET_LANGUAGE,
  DELETE_LANGUAGE,
  GET_SINGLE_LANGUAGE,
  UPDATE_LANGUAGE,
} from "../actions/types";

const INITIAL_STATE = {
  languages: [],
  language: {},
  loading: true,
  pagination: [],
};

export default function languageReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_LANGUAGE:
      return {
        ...state,
        loading: false,
        languages: action.payload.languages,
        pagination: action.payload.pagination,
      };

    case GET_SINGLE_LANGUAGE:
      return {
        ...state,
        loading: false,
        language: {
          ...state.languages.find(
            (act) => act.languageId === action.payload
          ),
        },
      };

    case DELETE_LANGUAGE:
      return {
        ...state,
        loading: false,
        languages: state.languages.filter(
          (act) => act.languageId !== action.payload
        ),
      };

    case UPDATE_LANGUAGE:
      console.log(action.payload);
      return {
        ...state,
        language: action.payload,
        loading: false,
      };

    default:
      return state;
  }
}
