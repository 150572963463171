import React from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import Sidebar from './Sidebar';
export default function AuthLayout({ children }) {
  return (
    <div className="wrapper show">
      <div className="body-content">

        <Sidebar />
        <div className="page__content">
          <Header />
          {children}

          <footer>
            Copyright © 2021 botrich.com. All Rights Reserved
				  </footer>
        </div>
      </div>
    </div>
  );
}

AuthLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
