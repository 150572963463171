import { axiosInstance } from "./interceptor";

export const getAllHashtags = async (url) => {
  try {
    return await axiosInstance.get(url);
  } catch (error) {
    return error.response;
  }
};

export const getVideoLink = async (url) => {
  try {
    return await axiosInstance.get(url);
  } catch (error) {
    return error.response;
  }
};

export const createGetVideoLink = async (url, data) => {
  try {
    return await axiosInstance.put(url, data);
  } catch (error) {
    return error.response;
  }
};
export const updateGetVideoLink = async (url, data) => {
  try {
    return await axiosInstance.patch(url, data);
  } catch (error) {
    return error.response;
  }
};

export const deleteVideoLink = async (url) => {
  try {
    return await axiosInstance.delete(url);
  } catch (error) {
    return error.response;
  }
};



export const getAllPosts = async (url) => {
  try {
    return await axiosInstance.get(url);
  } catch (error) {
    return error.response;
  }
};


export const updateisFeature = async (url, data) => {
  try {
    return await axiosInstance.patch(url, data);
  } catch (error) {
    return error.response;
  }
};


export const createAHashtag = async (url, data) => {
  try {
    return await axiosInstance.post(url, data);
  } catch (error) {
    return error.response;
  }
};
export const updateAHashtag = async (url, data) => {
  try {
    return await axiosInstance.patch(url, data);
  } catch (error) {
    return error.response;
  }
};
export const getAHashtag = async (url) => {
  try {
    return await axiosInstance.get(url);
  } catch (error) {
    return error.response;
  }
};
export const deleteHashtag = async (url) => {
  try {
    return await axiosInstance.delete(url);
  } catch (error) {
    return error.response;
  }
};
