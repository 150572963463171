import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "../routes";
const loading = (
  // <h1>Loading...</h1>
  <div className="loader-panel">
    <div className="lds-ring">
      <div></div>
      <div></div>
      <div></div>
    </div>
    Loading...
  </div>
);
function App() {
  return (
    <React.Suspense fallback={loading}>
      <Router>
        <Routes />
      </Router>
    </React.Suspense>
  );
}

export default App;
