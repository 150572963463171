import React, { useState, useEffect } from "react";
import { createAHashtag } from "../../utils/services/hashtags";
import { Alert } from "../../utils/Alert";
import { useHistory, useParams } from "react-router-dom";
import { addFeaturedProfessional, editFeaturedProfessional, getAllFeatureUser } from "../../utils/services/featureUser";
import Select from 'react-select';

const EditFeatureUser = () => {
    const [value, setValue] = useState({});
    const [designation, setDesignation] = useState("");

    const [businessDescription, setBusinessDescription] = useState("");
    const [professional, setProfessional] = useState([]);
    const [allUser, setAllUser] = useState([]);
    const history = useHistory();
    const params = useParams();

    console.log(params)


    const createProf = () => {
        
      
          if (!designation) {
            Alert.fire({
              icon: "error",
              title: "Designation is required",
            });
            return
          }
      
          if (!businessDescription) {
            Alert.fire({
              icon: "error",
              title: "Business Description is required",
            });
            return
          }
        let data = {
            ownerDesignation: designation,
            businessDescription: businessDescription,
            professionalId : value[0]?.value || value?.value,


          }
        editFeaturedProfessional(`/admin/featured-professional/update-featured-professional/${params.id}`, data)
            .then((res) => {

                console.log(res, "myres");

                if (res.status === 200 || res.status === 201) {
                    Alert.fire({
                        icon: "success",
                        title: res.data.message,
                    });
          history.push("/feature-user-management")

                }

                if (res.status === 409 || res.status === 400 || res.status === 500) {
                    Alert.fire({
                        icon: "error",
                        title: res.data.message,
                    });
                }
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        getAllHashtagsData()
        getAllFeatureProfessional()
    }, [])


    useEffect(() => {

        const filterData = allUser.filter(prof => prof.featuredProfessionalId == params.id)
    console.log(filterData, "filter")
    
        const oldUser = filterData?.map((each) => {
            return {
                value: each ? each?.professional?.professionalId : null,
                label: each ? each?.professional?.businessName : null,
            };
        });
        console.log(oldUser[0]?.value, "oldUser")
        setValue(oldUser)
        setDesignation(filterData?.[0]?.professional?.ownerDesignation)
        setBusinessDescription(filterData?.[0]?.professional?.businessDescription)
    }, [allUser, params.id])


    const getAllFeatureProfessional = () => {
        getAllFeatureUser(`/admin/featured-professional/get-featured-professionals`)
          .then((res) => {
           setAllUser(res.data.featuredProfessionals)
            console.log(res, "myres");
          })
          .catch((err) => console.log(err));
      };


    const getAllHashtagsData = () => {
        getAllFeatureUser(`/admin/featured-professional/get-professionals-list`)
            .then((res) => {
                setProfessional(res.data.professionals)
            })
            .catch((err) => console.log(err));
    };


    const newUsers = professional?.map((each) => {
        return {
            value: each ? each.professionalId : null,
            label: each ? each.businessName : null,
        };
    });


   

    

    return (
        <div className="body__panel">
            <div className="bdy-top-heading-area">
                <h4 className="heading">Update Feature User</h4>
            </div>

            <div className="panel margin40">
                <div className="form-row">
                    <div className="frm-clm-1">
                        <label htmlFor="">
                        Select professional<sup>*</sup>
                        </label>

                  
                        <Select
                            value={value}
                            // isMulti
                            name="colors"
                            options={newUsers}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(e) => {
                                console.log(e, "e")
                                setValue(e)}}

                        />
                      
                    </div>

                    <div className="frm-clm-1">
            <label htmlFor="">
              Designation<sup>*</sup>
            </label>
            <input
              type="text"
              name="designation"
              className="form-control"
              maxLength={64}
              onChange={(e) => {
                console.log(e.target.value)
                setDesignation(e.target.value)
              }}
              value={designation}
            />

            
            <span className="errorMsg">
              {/* {errors.title && touched.title && errors.title} */}
            </span>
          </div>


          <div className="frm-clm-1">
            <label htmlFor="">
              Business Description<sup>*</sup>
            </label>

            <textarea
                  type="text"
                  name="businessDescription"
                  className="form-control"
                  onChange={(e) => setBusinessDescription(e.target.value)}
                  value={businessDescription}
                  style={{
                    width: "100%",
                    padding: "10px",
                    marginBottom: "5px",
                  }}
                  rows="5"
                />


          

            
            <span className="errorMsg">
              {/* {errors.title && touched.title && errors.title} */}
            </span>
          </div>


                    <div>
                        <button
                            type="submit"
                            onClick={createProf}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditFeatureUser;
