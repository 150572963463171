import {
  GET_LUCKY_DRAW,
  DELETE_LUCKY_DRAW,
  GET_SINGLE_LUCKY_DRAW,
  UPDATE_LUCKY_DRAW,
} from "../actions/types";

const INITIAL_STATE = {
  luckyDraws: [],
  luckyDraw: {},
  loading: true,
  pagination: [],
};

export default function luckyDrawReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_LUCKY_DRAW:
      return {
        ...state,
        loading: false,
        luckyDraws: action.payload.promotions,
        pagination: action.payload.pagination,
      };

    case GET_SINGLE_LUCKY_DRAW:
      return {
        ...state,
        loading: false,
        luckyDraw: {
          ...state.luckyDraws.find(
            (act) => act.promotionId === action.payload
          ),
        },
      };

    case DELETE_LUCKY_DRAW:
      return {
        ...state,
        loading: false,
        luckyDraws: state.luckyDraws.filter(
          (act) => act.promotionId !== action.payload
        ),
      };

    case UPDATE_LUCKY_DRAW:
      console.log(action.payload);
      return {
        ...state,
        luckyDraw: action.payload,
        loading: false,
      };

    default:
      return state;
  }
}
