import {
    GET_ACTIVITY_TYPE,
    DELETE_ACTIVITY_TYPE,
    GET_SINGLE_ACTIVITY_TYPE,
    UPDATE_ACTIVITY_TYPE,
  } from "../actions/types";
  
  const INITIAL_STATE = {
    activityTypes: [],
    activityType: {},
    loading: true,
    pagination: []
  };
  
  export default function activityReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_ACTIVITY_TYPE:
            return {
            ...state,
            loading: false,
            activityTypes: action.payload.activityTypes,
            pagination: action.payload.pagination
            };
  
      case GET_SINGLE_ACTIVITY_TYPE:
        return {
          ...state,
          loading: false,
          activityType: {
            ...state.activityTypes.find(
              (act) => act.activityTypeId === action.payload
            ),
          },
        };
  
      case DELETE_ACTIVITY_TYPE:
        return {
          ...state,
          loading: false,
          activityTypes: state.activityTypes.filter(
            (act) => act.activityTypeId !== action.payload
          ),
        };
  
        case UPDATE_ACTIVITY_TYPE:
          console.log(action.payload)
        return {
          ...state,
          activityType: action.payload,
          loading: false,
        };
  
      default:
        return state;
    }
  }
  