import React, { Component } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";

function Sidebar() {
  const [openSubMenu, setOpenSubmenu] = React.useState(false);
  const [openSubSUBMenu, setOpenSubSubmenu] = React.useState(false);
  const [openManagementSubMenu, setOpenmanageSubmenu] = React.useState(false);
  const [openNewsSubMenu, setOpenNewsSubMenu] = React.useState(false);
  const [openBotrichRecommendationItem, setOpenBotrichRecommendationItem] =
    React.useState(false);
  const history = useHistory();
  const setTooglemenu = () => {
    setOpenSubmenu(true);
  };
  // React.useEffect(() => {
  //   console.log(history)
  //   const categoryRoutes = ["/interest-management","/post-category","/event-category"]
  //   if(categoryRoutes.includes(history.location.pathname)) {
  //     setOpenSubmenu(true)
  //   }
  //   const professionalRoutes = ["/professional","/professional-management","/requested-business-catagory-management"]
  //   if(professionalRoutes.includes(history.location.pathname)) {
  //     setOpenSubmenu(true)
  //   }
  //   const stageRoutes = ["/video-management","/botrich-event","/botrich-recommendation-item","/stage-event-management"]
  //   if(stageRoutes.includes(history.location.pathname)) {
  //     setOpenSubmenu(true)
  //   }
  // }, []);
  return (
    <>
      <div className="sidebar">
        <div className="top__logoprt hidden__mb">
          <a href="#">
            <img
              className="logo"
              src={`${process.env.PUBLIC_URL}/images/short-logo.png`}
              alt=""
            />
            <span>
              <img
                className="logo"
                src={`${process.env.PUBLIC_URL}/images/logo-txt.png`}
                alt=""
              />
            </span>
          </a>
        </div>

        <div className="top_userpanel block__mb">
          <div className="flex">
            <a href="profile.html">
              <img
                src={`${process.env.PUBLIC_URL}/images/profile_img.png`}
                alt=""
              />
            </a>
            <div className="userdetail">
              <h3>Masood aremu</h3>
              <small>massod@gmail.com</small>
              <button onclick="window.location.href = 'edit_profile.html';">
                Edit Profile
              </button>
            </div>
          </div>
          <button type="button" className="closebtn sidemenuclose">
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/cross.png`}
              alt=""
            />
          </button>
        </div>

        <div className="menu">
          <Scrollbars className="fullheight">
            <ul>
              <li>
                <NavLink to="/dashboard" activeClassName="active">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/icon/dashboard.png`}
                    alt=""
                  />
                  <span>Dashboard</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/landing-page-banner-manegement"
                  activeClassName="active"
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/icon/banner-icon.png`}
                    alt=""
                  />
                  <span>Landing page banner management</span>
                </NavLink>
              </li>

              <li
                onClick={() => setOpenSubmenu(!openSubMenu)}
                className="submenuprt"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/icon/category.png`}
                  alt=""
                />{" "}
                Category Management
              </li>
              {openSubMenu ? (
                <ul className="submenu-list">
                  <li onClick={() => setTooglemenu(openSubMenu)}>
                    <NavLink to="/interest-management" activeClassName="active">
                      <span> Interest Management </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/post-category" activeClassName="active">
                      <span>Post Category Management</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/event-category" activeClassName="active">
                      <span>Event Category Management</span>
                    </NavLink>
                  </li>
                  <li
                    onClick={() => setOpenSubSubmenu(!openSubSUBMenu)}
                    className="subsubmenuprt"
                  >
                    {/* <NavLink to="/business-types" activeClassName="active"> */}
                    <span>Professional Category Management</span>
                    {/* </NavLink> */}

                    {openSubSUBMenu ? (
                      <ul>
                        <li onClick={() => setOpenSubSubmenu(!openSubSUBMenu)}>
                          <NavLink
                            to="/business-types"
                            activeClassName="active"
                          >
                            <span>Professional Categories</span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/service-category"
                            activeClassName="active"
                          >
                            <span>Service Categories</span>
                          </NavLink>
                        </li>
                      </ul>
                    ) : null}
                  </li>

                  {/* <li>
                <NavLink to="/certificate-types" activeClassName="active">
                  <img src={`${process.env.PUBLIC_URL}/images/icon/certificate.png`} alt="" />
                  <span>Certificate Categories</span>
                </NavLink>
              </li> */}
                </ul>
              ) : null}

              <li
                onClick={() => setOpenmanageSubmenu(!openManagementSubMenu)}
                className="submenuprt"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/icon/professional-management-icon.png`}
                  alt=""
                />{" "}
                Professional Management
              </li>

              {openManagementSubMenu ? (
                <ul className="submenu-list">
                  <li>
                    <NavLink to="/professional" activeClassName="active">
                      <span>Applied Professional Management</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/professional-management"
                      activeClassName="active"
                    >
                      <span>Approved Professional Management </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/requested-business-catagory-management"
                      activeClassName="active"
                    >
                      <span> Requested Business Category Management </span>
                    </NavLink>
                  </li>
                </ul>
              ) : null}

              <li
                onClick={() => setOpenNewsSubMenu(!openNewsSubMenu)}
                className="submenuprt"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/icon/terms.png`}
                  alt=""
                />{" "}
                Stage Management
              </li>
              {openNewsSubMenu ? (
                <ul className="submenu-list">
                  <li>
                    <NavLink to="/video-management" activeClassName="active">
                      <span> Video Management </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/botrich-event" activeClassName="active">
                      <span>Botrich Events</span>
                    </NavLink>
                  </li>

                  {/* <li>
                    <NavLink to="/botrich-article" activeClassName="active">
                      <span> Botrich article </span>
                    </NavLink>
                  </li> */}
                  <li>
                    <NavLink
                      to="/botrich-recommendation-item"
                      activeClassName="active"
                    >
                      <span> Botrich Recommendation Item </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/stage-event-management"
                      activeClassName="active"
                    >
                      <span> Stage Event Management </span>
                    </NavLink>
                  </li>
                </ul>
              ) : null}

              <li>
                <NavLink to="/language" activeClassName="active">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/icon/language.png`}
                    alt=""
                  />
                  <span>Languages</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/owner-documentation-types"
                  activeClassName="active"
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/icon/certificate.png`}
                    alt=""
                  />
                  <span>Owner Documentation Types</span>
                </NavLink>
              </li>

              <li>
                <NavLink to="/list-posts-management" activeClassName="active">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/icon/hashtag-management-icon.png`}
                    alt=""
                  />
                  <span> Posts Management </span>
                </NavLink>
              </li>

              <li>
                <NavLink to="/list-video-link-management" activeClassName="active">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/icon/hashtag-management-icon.png`}
                    alt=""
                  />
                  <span> Videos Link Management </span>
                </NavLink>
              </li>

              <li>
                <NavLink to="/list-hashtag-management" activeClassName="active">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/icon/hashtag-management-icon.png`}
                    alt=""
                  />
                  <span> Hashtag Management </span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/user-management" activeClassName="active">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/icon/user-management-icon.png`}
                    alt=""
                  />
                  <span> User Management </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/user-fund-withdraw-management"
                  activeClassName="active"
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/icon/user-fund-withdraw-icon.png`}
                    alt=""
                  />
                  <span> User Fund Withdraw Management </span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/add-acss-payment-method" activeClassName="active">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/icon/add-acss-payment-method-icon.png`}
                    alt=""
                  />
                  <span> Add ACSS Payment Method </span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/add-balance-to-stripe" activeClassName="active">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/icon/stripe-add-balance-icon.png`}
                    alt=""
                  />
                  <span> Add Balance To Stripe </span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/event-management" activeClassName="active">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/icon/event-management-icon.png`}
                    alt=""
                  />
                  <span> Event Management </span>
                </NavLink>
              </li>

              <li>
                <NavLink to="/service-management" activeClassName="active">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/icon/service-management-icon.png`}
                    alt=""
                  />
                  <span> Service Management </span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/faq" activeClassName="active">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/icon/faq-icon-2.png`}
                    alt=""
                  />
                  <span> FAQ </span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/keyword-management" activeClassName="active">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/icon/keyword-icon.png`}
                    alt=""
                  />
                  <span> Keyword management </span>
                </NavLink>
              </li>

              <li>
                <NavLink to="/feature-user-management" activeClassName="active">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/icon/feature-user-icon.png`}
                    alt=""
                  />
                  <span> Feature Professional Management </span>
                </NavLink>
              </li>

           

            </ul>
          </Scrollbars>
        </div>
      </div>
    </>
  );
}
export default Sidebar;
