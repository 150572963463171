import { combineReducers } from "redux";
import AdminLoginReducer from "./AdminLoginReducer";
import certificateReducer from "./certificateReducer";
import activityReducer from "./activityReducer";
import businessReducer from "./businessReducer";
import postReducer from "./postReducer";
import serviceReducer from "./serviceReducer";
import eventReducer from "./eventReducer";
import languageReducer from "./languageReducer";
import ownerDocumentationTypeReducer from "./ownerDocumentationTypeReducer";
import professionalReducer from "./professionalReducer";
import luckyDrawReducer from "./luckyDrawReducer";

export default combineReducers({
  AdminLoginReducer,
  certificateReducer,
  activityReducer,
  businessReducer,
  postReducer,
  serviceReducer,
  eventReducer,
  languageReducer,
  ownerDocumentationTypeReducer,
  professionalReducer,
  luckyDrawReducer,
});
