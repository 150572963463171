import { axiosInstance } from './services/interceptor';

export const getAuthToken = () => {
    return localStorage.getItem("token");
};



export const isLoggedIn = () => {
    if (localStorage.getItem("token") !== null) {
        return true
    } else {
        return false
    }
}

export const logOut = async (url, token) => {
    try {
        localStorage.removeItem('token');
        await axiosInstance.delete(url, { headers: {"Authorization" : `Bearer ${token}`}});
        window.location.href="/"
        return true
      } catch (error) {
        return error.response;
      }
    // localStorage.removeItem('token');
}