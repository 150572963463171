import {
  GET_EVENT_CATEGORY,
  DELETE_EVENT_CATEGORY,
  GET_SINGLE_EVENT_CATEGORY,
  UPDATE_EVENT_CATEGORY,
} from "../actions/types";

const INITIAL_STATE = {
  eventCategories: [],
  eventCategory: {},
  loading: true,
  pagination: [],
};

export default function eventReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_EVENT_CATEGORY:
      return {
        ...state,
        loading: false,
        eventCategories: action.payload.eventCategories,
        pagination: action.payload.pagination,
      };

    case GET_SINGLE_EVENT_CATEGORY:
      return {
        ...state,
        loading: false,
        eventCategory: {
          ...state.eventCategories.find(
            (act) => act.eventCategoryId === action.payload
          ),
        },
      };

    case DELETE_EVENT_CATEGORY:
      return {
        ...state,
        loading: false,
        eventCategories: state.eventCategories.filter(
          (act) => act.eventCategoryId !== action.payload
        ),
      };

    case UPDATE_EVENT_CATEGORY:
      console.log(action.payload);
      return {
        ...state,
        eventCategory: action.payload,
        loading: false,
      };

    default:
      return state;
  }
}
