import React from "react";
import EditFeatureUser from "../pages/FrontUserManagement/editFeatureUser";

// const RecoverPassword = React.lazy(() =>
//   import('../pages/ForgotPassword')
//     .then(({ ForgotPassword, ResetPassword }) => ({ default: ForgotPassword })),
// );

const Login = React.lazy(() => import("../pages/Login"));
const Dashboard = React.lazy(() => import("../pages/Dashboard"));
const Certificate = React.lazy(() => import("../pages/Certificate"));
const ListCertificates = React.lazy(() =>
  import("../pages/Certificate/listCertificates")
);
const createCertificateType = React.lazy(() =>
  import("../pages/Certificate/createCertificateType")
);
const editCertificateType = React.lazy(() =>
  import("../pages/Certificate/editCertificateType")
);
const UserList = React.lazy(() => import("../pages/UserList"));
const ServiceProviderList = React.lazy(() =>
  import("../pages/ServiceProvider")
);
const listActivitTypes = React.lazy(() =>
  import("../pages/Activity/listActivitTypes")
);
const createActivityType = React.lazy(() =>
  import("../pages/Activity/createActivityType")
);
const editActivityType = React.lazy(() =>
  import("../pages/Activity/editActivityType")
);

const listBusinessTypes = React.lazy(() =>
  import("../pages/Business/listBusinessTypes")
);
const createBusinessType = React.lazy(() =>
  import("../pages/Business/createBusinessType")
);
const editBusinessType = React.lazy(() =>
  import("../pages/Business/editBusinessType")
);

const listPostCategories = React.lazy(() =>
  import("../pages/Post/listPostCategories")
);
const createPostCategory = React.lazy(() =>
  import("../pages/Post/createPostCategory")
);
const editPostCategory = React.lazy(() =>
  import("../pages/Post/editPostCategory")
);

const listServiceCategories = React.lazy(() =>
  import("../pages/Service/listServiceCategories")
);
const createServiceCategory = React.lazy(() =>
  import("../pages/Service/createServiceCategory")
);
const editServiceCategory = React.lazy(() =>
  import("../pages/Service/editServiceCategory")
);

const listEventCategories = React.lazy(() =>
  import("../pages/Event/listEventCategories")
);
const createEventCategory = React.lazy(() =>
  import("../pages/Event/createEventCategory")
);
const editEventCategory = React.lazy(() =>
  import("../pages/Event/editEventCategory")
);

const listLanguages = React.lazy(() =>
  import("../pages/Language/listLanguages")
);
const createLanguage = React.lazy(() =>
  import("../pages/Language/createLanguage")
);
const editLanguage = React.lazy(() => import("../pages/Language/editLanguage"));

const listOwnerDocumentationTypes = React.lazy(() =>
  import("../pages/OwnerDocumentationTypes/listOwnerDocumentationTypes")
);
const createOwnerDocumentationType = React.lazy(() =>
  import("../pages/OwnerDocumentationTypes/createOwnerDocumentationType")
);
const editOwnerDocumentationType = React.lazy(() =>
  import("../pages/OwnerDocumentationTypes/editOwnerDocumentationType")
);

const listProfessional = React.lazy(() =>
  import("../pages/Professional/listProfessional")
);
const detailProfessional = React.lazy(() =>
  import("../pages/Professional/detailProfessional")
);

const listLuckyDraws = React.lazy(() =>
  import("../pages/BotrichEvent/listLuckyDraws")
);
const createLuckyDraw = React.lazy(() =>
  import("../pages/BotrichEvent/createLuckyDraw")
);
const editLuckyDraw = React.lazy(() =>
  import("../pages/BotrichEvent/editLuckyDraw")
);
const viewParticipants = React.lazy(() =>
  import("../pages/BotrichEvent/viewParticipants")
);
const viewWinners = React.lazy(() =>
  import("../pages/BotrichEvent/viewWinners")
);
const viewQr = React.lazy(() => import("../pages/BotrichEvent/viewQr"));

const createRequestedBusinessCatagoryManagement = React.lazy(() =>
  import(
    "../pages/RequestedBusinessCategoryManagement/createRequestedBusinessCatagoryManagement"
  )
);
const editRequestedBusinessCatagoryManagement = React.lazy(() =>
  import(
    "../pages/RequestedBusinessCategoryManagement/editRequestedBusinessCatagoryManagement"
  )
);

const listRequestedBusinessCatagoryManagement = React.lazy(() =>
  import(
    "../pages/RequestedBusinessCategoryManagement/listRequestedBusinessCatagoryManagement"
  )
);
const createInterestManagement = React.lazy(() =>
  import("../pages/InterestManagement/createInterestManagement")
);
const editInterestManagement = React.lazy(() =>
  import("../pages/InterestManagement/editInterestManagement")
);
const listInterestManagement = React.lazy(() =>
  import("../pages/InterestManagement/listInterestManagement")
);

const listHashtagManagement = React.lazy(() =>
  import("../pages/HashtagManagement/listHashtagManagement")
);
const listPostManagement = React.lazy(() =>
  import("../pages/PostsManagement/listpostsmanagement")
);

const postDetails = React.lazy(() =>
  import("../pages/PostDetails/postdetailspage")
);

const createHashtagManagement = React.lazy(() =>
  import("../pages/HashtagManagement/createHashtagManagement")
);
const editHashtagManagement = React.lazy(() =>
  import("../pages/HashtagManagement/editHashtagManagement")
);

const listUserManagement = React.lazy(() =>
  import("../pages/UserManagement/listUserManagement")
);
const listProfessionalManagement = React.lazy(() =>
  import("../pages/ProfessionalManagement/listProfessionalManagement")
);
const listServiceManagement = React.lazy(() =>
  import("../pages/ServiceManagement/listServiceManagement")
);
const ServiceManagementDetails = React.lazy(() =>
  import("../pages/ServiceManagement/serviceManagementDetails")
);

const listEventManagement = React.lazy(() =>
  import("../pages/EventManagement/listEventManagement")
);
const eventManagementDetails = React.lazy(() =>
  import("../pages/EventManagement/eventManagementDetails")
);
const createEventManagement = React.lazy(() =>
  import("../pages/EventManagement/createEventManagement")
);
const listBotrichArticle = React.lazy(() =>
  import("../pages/NewsManagement/Botrich_Article/listBotrichArticle")
);
const createBotrichArticle = React.lazy(() =>
  import("../pages/NewsManagement/Botrich_Article/createBotrichArticle")
);

const editBotrichArticle = React.lazy(() =>
  import("../pages/NewsManagement/Botrich_Article/editBotrichArticle")
);
const listBotrichRecommendationItem = React.lazy(() =>
  import(
    "../pages/NewsManagement/Botrich_Recommendation_Item/listBotrichRecommendationItem"
  )
);
const createBotrichRecommendationItem = React.lazy(() =>
  import(
    "../pages/NewsManagement/Botrich_Recommendation_Item/createBotrichRecommendationItem"
  )
);
const editBotrichRecommendationItem = React.lazy(() =>
  import(
    "../pages/NewsManagement/Botrich_Recommendation_Item/editBotrichRecommendationItem"
  )
);

const listVideoLinkManagement = React.lazy(() =>
  import("../pages/VideolinkManagement/listvideomanagement")
);

const listVideoManagement = React.lazy(() =>
  import("../pages/NewsManagement/Video_Management/listVideoManagement")
);
const createVideoManagement = React.lazy(() =>
  import("../pages/NewsManagement/Video_Management/createVideoManagement")
);
const editVideoManagement = React.lazy(() =>
  import("../pages/NewsManagement/Video_Management/editVideoManagement")
);
const faqPage = React.lazy(() => import("../pages/FaqSection/FaqPage"));
const faqEditPage = React.lazy(() => import("../pages/FaqSection/EditFaq"));
const createFaq = React.lazy(() => import("../pages/FaqSection/createFaq"));

const listLandingPageBannerManagement = React.lazy(() =>
  import("../pages/BannerManagement/listLandingPageBannerManagement")
);
const createLandingPageBannerManagement = React.lazy(() =>
  import("../pages/BannerManagement/createLandingPageBannerManagement")
);
const editLandingPageBannerManagement = React.lazy(() =>
  import("../pages/BannerManagement/editLandingPageBannerManagement")
);
const listStageEventManegement = React.lazy(() =>
  import(
    "../pages/NewsManagement/Stage_Event_Management/listStageEventManagement"
  )
);
const editStageEventManegement = React.lazy(() =>
  import(
    "../pages/NewsManagement/Stage_Event_Management/editStageEventManagement"
  )
);
const createStageEventManegement = React.lazy(() =>
  import(
    "../pages/NewsManagement/Stage_Event_Management/createStageEventManagement"
  )
);
const userDetails = React.lazy(() =>
  import("../pages/UserManagement/UserDetails")
);

const userFundWithdrawManagement = React.lazy(() =>
  import("../pages/UserFundWithdrawManagement/userFundWithdrawManagement")
);

const addBalanceToStripe = React.lazy(() =>
  import("../pages/AddBalanceToStripe/addBalanceToStripe")
);

const addAcssPaymentMethod = React.lazy(() =>
  import("../pages/AddAcssPaymentMethod/addAcssPaymentMethod")
);

const keywordManagement = React.lazy(() =>
  import("../pages/KeywordManagement/listkeywordmanagement")
);


const featureUser = React.lazy(() =>
  import("../pages/FrontUserManagement/featureUser")
);


const createFeatureUser = React.lazy(() =>
  import("../pages/FrontUserManagement/createFeatureUser")
);

const editFeatureUser = React.lazy(() =>
  import("../pages/FrontUserManagement/editFeatureUser")
);


const allRoutes = [
  {
    path: "/",
    name: "Login",
    exact: true,
    component: Login,
    isPrivate: false,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    exact: true,
    component: Dashboard,
    isPrivate: true,
  },
  {
    path: "/certificate",
    name: "Certificate",
    exact: true,
    component: Certificate,
    isPrivate: true,
  },
  {
    path: "/certificate-types",
    name: "ListCertificates",
    exact: true,
    component: ListCertificates,
    isPrivate: true,
  },
  {
    path: "/create-certificate-type",
    name: "createCertificateType",
    exact: true,
    component: createCertificateType,
    isPrivate: true,
  },
  {
    path: "/edit-certificate-type/:id",
    name: "editCertificateType",
    exact: true,
    component: editCertificateType,
    isPrivate: true,
  },
  {
    path: "/user-list",
    name: "UserList",
    exact: true,
    component: UserList,
    isPrivate: true,
  },
  {
    path: "/service-provider",
    name: "ServiceProviderList",
    exact: true,
    component: ServiceProviderList,
    isPrivate: true,
  },
  {
    path: "/activity-types",
    name: "listActivitTypes",
    exact: true,
    component: listActivitTypes,
    isPrivate: true,
  },
  {
    path: "/create-activity-type",
    name: "createActivityType",
    exact: true,
    component: createActivityType,
    isPrivate: true,
  },
  {
    path: "/edit-activity-type/:id",
    name: "editActivityType",
    exact: true,
    component: editActivityType,
    isPrivate: true,
  },
  {
    path: "/business-types",
    name: "listBusinessTypes",
    exact: true,
    component: listBusinessTypes,
    isPrivate: true,
  },
  {
    path: "/create-business-type",
    name: "createBusinessType",
    exact: true,
    component: createBusinessType,
    isPrivate: true,
  },
  {
    path: "/edit-business-type/:id",
    name: "editBusinessType",
    exact: true,
    component: editBusinessType,
    isPrivate: true,
  },
  {
    path: "/post-category",
    name: "listPostCategories",
    exact: true,
    component: listPostCategories,
    isPrivate: true,
  },
  {
    path: "/create-post-category",
    name: "createPostCategory",
    exact: true,
    component: createPostCategory,
    isPrivate: true,
  },
  {
    path: "/edit-post-category/:id",
    name: "editPostCategory",
    exact: true,
    component: editPostCategory,
    isPrivate: true,
  },
  {
    path: "/service-category",
    name: "listServiceCategories",
    exact: true,
    component: listServiceCategories,
    isPrivate: true,
  },
  {
    path: "/create-service-category",
    name: "createServiceCategory",
    exact: true,
    component: createServiceCategory,
    isPrivate: true,
  },
  {
    path: "/edit-service-category/:id",
    name: "editServiceCategory",
    exact: true,
    component: editServiceCategory,
    isPrivate: true,
  },
  {
    path: "/event-category",
    name: "listEventCategories",
    exact: true,
    component: listEventCategories,
    isPrivate: true,
  },
  {
    path: "/create-event-category",
    name: "createEventCategory",
    exact: true,
    component: createEventCategory,
    isPrivate: true,
  },
  {
    path: "/edit-event-category/:id",
    name: "editEventCategory",
    exact: true,
    component: editEventCategory,
    isPrivate: true,
  },
  {
    path: "/language",
    name: "listLanguages",
    exact: true,
    component: listLanguages,
    isPrivate: true,
  },
  {
    path: "/create-language",
    name: "createLanguage",
    exact: true,
    component: createLanguage,
    isPrivate: true,
  },
  {
    path: "/edit-language/:id",
    name: "editLanguage",
    exact: true,
    component: editLanguage,
    isPrivate: true,
  },
  {
    path: "/owner-documentation-types",
    name: "listOwnerDocumentationTypes",
    exact: true,
    component: listOwnerDocumentationTypes,
    isPrivate: true,
  },
  {
    path: "/create-owner-documentation-types",
    name: "createOwnerDocumentationType",
    exact: true,
    component: createOwnerDocumentationType,
    isPrivate: true,
  },
  {
    path: "/edit-owner-documentation-types/:id",
    name: "editOwnerDocumentationType",
    exact: true,
    component: editOwnerDocumentationType,
    isPrivate: true,
  },
  {
    path: "/professional",
    name: "listProfessional",
    exact: true,
    component: listProfessional,
    isPrivate: true,
  },
  {
    path: "/professional-details/:id",
    name: "detailProfessional",
    exact: true,
    component: detailProfessional,
    isPrivate: true,
  },
  {
    path: "/botrich-event",
    name: "listLuckyDraws",
    exact: true,
    component: listLuckyDraws,
    isPrivate: true,
  },
  {
    path: "/create-botrich-event",
    name: "createLuckyDraw",
    exact: true,
    component: createLuckyDraw,
    isPrivate: true,
  },
  {
    path: "/view-qrcode/:id",
    name: "viewQr",
    exact: true,
    component: viewQr,
    isPrivate: true,
  },

  {
    path: "/edit-botrich-event/:id",
    name: "editLuckyDraw",
    exact: true,
    component: editLuckyDraw,
    isPrivate: true,
  },
  {
    path: "/botrich-event/view-participants/:id",
    name: "viewParticipants",
    exact: true,
    component: viewParticipants,
    isPrivate: true,
  },
  {
    path: "/botrich-event/view-winners/:id",
    name: "viewWinners",
    exact: true,
    component: viewWinners,
    isPrivate: true,
  },

  {
    path: "/requested-business-catagory-management",
    name: "listRequestedBusinessCatagoryManagement",
    exact: true,
    component: listRequestedBusinessCatagoryManagement,
    isPrivate: true,
  },
  {
    path: "/edit-requested-business-catagory-management/:id",
    name: "editRequestedBusinessCatagoryManagement",
    exact: true,
    component: editRequestedBusinessCatagoryManagement,
    isPrivate: true,
  },

  //
  {
    path: "/edit-interest-management/:id",
    name: "editInterestManagement",
    exact: true,
    component: editInterestManagement,
    isPrivate: true,
  },
  {
    path: "/interest-management",
    name: "listInterestManagement",
    exact: true,
    component: listInterestManagement,
    isPrivate: true,
  },

  {
    path: "/create-interest-management",
    name: "createInterestManagement",
    exact: true,
    component: createInterestManagement,
    isPrivate: true,
  },

  {
    path: "/list-posts-management",
    name: "listposttagmanagement",
    exact: true,
    component: listPostManagement,
    isPrivate: true,
  },

  {
    path: "/list-video-link-management",
    name: "listvideomanagement",
    exact: true,
    component: listVideoLinkManagement,
    isPrivate: true,
  },

  {
    path: "/post-details/:id",
    name: "postDetails",
    exact: true,
    component: postDetails,
    isPrivate: true,
  },
  
  {
    path: "/list-hashtag-management",
    name: "listhashtagmanagement",
    exact: true,
    component: listHashtagManagement,
    isPrivate: true,
  },
  {
    path: "/create-hashtag-management",
    name: "createhashtagmanagement",
    exact: true,
    component: createHashtagManagement,
    isPrivate: true,
  },
  {
    path: "/edit-hashtag-management/:id",
    name: "edithashtagmanagement",
    exact: true,
    component: editHashtagManagement,
    isPrivate: true,
  },

  {
    path: "/user-management",
    name: "usermanagement",
    exact: true,
    component: listUserManagement,
    isPrivate: true,
  },
  {
    path: "/professional-management",
    name: "professionalmanagement",
    exact: true,
    component: listProfessionalManagement,
    isPrivate: true,
  },
  {
    path: "/service-management",
    name: "servicemanagement",
    exact: true,
    component: listServiceManagement,
    isPrivate: true,
  },
  {
    path: "/view-service-management/:id",
    name: "servicemanagementdetails",
    exact: true,
    component: ServiceManagementDetails,
    isPrivate: true,
  },

  {
    path: "/event-management",
    name: "eventmanagement",
    exact: true,
    component: listEventManagement,
    isPrivate: true,
  },
  {
    path: "/create-event-management",
    name: "createeventmanagement",
    exact: true,
    component: createEventManagement,
    isPrivate: true,
  },
  {
    path: "/event-details/:id",
    name: "eventdetails",
    exact: true,
    component: eventManagementDetails,
    isPrivate: true,
  },

  {
    path: "/botrich-article",
    name: "botricharticle",
    exact: true,
    component: listBotrichArticle,
    isPrivate: true,
  },
  {
    path: "/create-botrich-article",
    name: "createbotricharticle",
    exact: true,
    component: createBotrichArticle,
    isPrivate: true,
  },
  {
    path: "/edit-botrich-article/:id",
    name: "editbotricharticle",
    exact: true,
    component: editBotrichArticle,
    isPrivate: true,
  },
  {
    path: "/botrich-recommendation-item",
    name: "botrichrecommendationitem",
    exact: true,
    component: listBotrichRecommendationItem,
    isPrivate: true,
  },
  {
    path: "/create-botrich-recommendation-item",
    name: "createbotrichrecommendationitem",
    exact: true,
    component: createBotrichRecommendationItem,
    isPrivate: true,
  },
  {
    path: "/edit-botrich-recommendation-item/:id",
    name: "editbotrichrecommendationitem",
    exact: true,
    component: editBotrichRecommendationItem,
    isPrivate: true,
  },
  {
    path: "/video-management",
    name: "videoManagement",
    exact: true,
    component: listVideoManagement,
    isPrivate: true,
  },
  {
    path: "/create-video-management",
    name: "createVideoManagement",
    exact: true,
    component: createVideoManagement,
    isPrivate: true,
  },
  {
    path: "/edit-video-management/:id",
    name: "editvideomanagement",
    exact: true,
    component: editVideoManagement,
    isPrivate: true,
  },
  {
    path: "/faq",
    name: "faq",
    exact: true,
    component: faqPage,
    isPrivate: true,
  },
  {
    path: "/edit-faq/:id",
    name: "editFaq",
    exact: true,
    component: faqEditPage,
    isPrivate: true,
  },
  {
    path: "/create-faq",
    name: "createFaq",
    exact: true,
    component: createFaq,
    isPrivate: true,
  },
  {
    path: "/landing-page-banner-manegement",
    name: "landingPageBannerManagement",
    exact: true,
    component: listLandingPageBannerManagement,
    isPrivate: true,
  },
  {
    path: "/create-landing-page-banner-management",
    name: "createLandingPageBannerManegement",
    exact: true,
    component: createLandingPageBannerManagement,
    isPrivate: true,
  },
  {
    path: "/edit-landing-page-banner-management/:id",
    name: "editLandingPageBannerManegement",
    exact: true,
    component: editLandingPageBannerManagement,
    isPrivate: true,
  },

  {
    path: "/stage-event-management",
    name: "liststageeventmanagement",
    exact: true,
    component: listStageEventManegement,
    isPrivate: true,
  },
    
  {
    path: "/create-stage-event-management",
    name: "createstageeventmanagement",
    exact: true,
    component: createStageEventManegement,
    isPrivate: true,
  },
  {
    path: "/user-details/:id",
    name: "userDetails",
    exact: true,
    component: userDetails,
    isPrivate: true,
  },
  {
    path: "/user-fund-withdraw-management",
    name: "userFundWithdrawManagement",
    exact: true,
    component: userFundWithdrawManagement,
    isPrivate: true,
  },
  {
    path: "/add-balance-to-stripe",
    name: "addBalanceToStripe",
    exact: true,
    component: addBalanceToStripe,
    isPrivate: true,
  },
  {
    path: "/add-acss-payment-method",
    name: "addAcssPaymentMethod",
    exact: true,
    component: addAcssPaymentMethod,
    isPrivate: true,
  },
  {
    path: "/keyword-management",
    name: "keywordManagement",
    exact: true,
    component: keywordManagement,
    isPrivate: true,
  },
  {
    path: "/feature-user-management",
    name: "featureUser",
    exact: true,
    component: featureUser,
    isPrivate: true,
  },

  {
    path: "/create-feature-user",
    name: "createFeatureUser",
    exact: true,
    component: createFeatureUser,
    isPrivate: true,
  },

  {
    path: "/edit-feature-user/:id",
    name: "editFeatureUser",
    exact: true,
    component: editFeatureUser,
    isPrivate: true,
  },

];

export { allRoutes };
