
// login reducer types
export const ADMIN_LOGIN_REQUEST = 'ADMIN_LOGIN_REQUEST';
export const ADMIN_LOGIN_SUCCESS = 'ADMIN_LOGIN_SUCCESS';
export const ADMIN_LOGIN_FAIL = 'ADMIN_LOGIN_FAIL';
export const ADMIN_LOGOUT = 'ADMIN_LOGOUT';
export const SET_INITIAL_USER = 'SET_INITIAL_USER';

// certificate
export const GET_PENDING_CERTIFICATES = 'GET_PENDING_CERTIFICATES';
export const GET_CERTIFICATES_TYPE = 'GET_CERTIFICATES_TYPE';
export const ACCEPT_CERTIFICATE = 'ACCEPT_CERTIFICATE';
export const DELETE_CERTIFICATES_TYPE = 'DELETE_CERTIFICATES_TYPE';
export const GET_SINGLE_CERTIFICATES_TYPE = 'GET_SINGLE_CERTIFICATES_TYPE';
export const UPDATE_CERTIFICATE_TYPE = 'UPDATE_CERTIFICATE_TYPE';


// activity
export const GET_ACTIVITY_TYPE = 'GET_ACTIVITY_TYPE';
export const DELETE_ACTIVITY_TYPE = 'DELETE_ACTIVITY_TYPE';
export const GET_SINGLE_ACTIVITY_TYPE = 'GET_SINGLE_ACTIVITY_TYPE';
export const UPDATE_ACTIVITY_TYPE = 'UPDATE_ACTIVITY_TYPE';

// business
export const GET_BUSINESS_TYPE = 'GET_BUSINESS_TYPE';
export const DELETE_BUSINESS_TYPE = 'DELETE_BUSINESS_TYPE';
export const GET_SINGLE_BUSINESS_TYPE = 'GET_SINGLE_BUSINESS_TYPE';
export const UPDATE_BUSINESS_TYPE = 'UPDATE_BUSINESS_TYPE';


// post
export const GET_POST_CATEGORY = 'GET_POST_CATEGORY';
export const DELETE_POST_CATEGORY = 'DELETE_POST_CATEGORY';
export const GET_SINGLE_POST_CATEGORY = 'GET_SINGLE_POST_CATEGORY';
export const UPDATE_POST_CATEGORY = 'UPDATE_POST_CATEGORY';


// service
export const GET_SERVICE_CATEGORY = 'GET_SERVICE_CATEGORY';
export const DELETE_SERVICE_CATEGORY = 'DELETE_SERVICE_CATEGORY';
export const GET_SINGLE_SERVICE_CATEGORY = 'GET_SINGLE_SERVICE_CATEGORY';
export const UPDATE_SERVICE_CATEGORY = 'UPDATE_SERVICE_CATEGORY';


// event
export const GET_EVENT_CATEGORY = 'GET_EVENT_CATEGORY';
export const DELETE_EVENT_CATEGORY = 'DELETE_EVENT_CATEGORY';
export const GET_SINGLE_EVENT_CATEGORY = 'GET_SINGLE_EVENT_CATEGORY';
export const UPDATE_EVENT_CATEGORY = 'UPDATE_EVENT_CATEGORY';


// event
export const GET_LANGUAGE = 'GET_LANGUAGE';
export const DELETE_LANGUAGE = 'DELETE_LANGUAGE';
export const GET_SINGLE_LANGUAGE = 'GET_SINGLE_LANGUAGE';
export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE';


// OwnerDocumentationType
export const GET_OWNER_DOCUMENTATION_TYPE = 'GET_OWNER_DOCUMENTATION_TYPE';
export const DELETE_OWNER_DOCUMENTATION_TYPE = 'DELETE_OWNER_DOCUMENTATION_TYPE';
export const GET_SINGLE_OWNER_DOCUMENTATION_TYPE = 'GET_SINGLE_OWNER_DOCUMENTATION_TYPE';
export const UPDATE_OWNER_DOCUMENTATION_TYPE = 'UPDATE_OWNER_DOCUMENTATION_TYPE';


// Professional
export const GET_PROFESSIONAL = 'GET_PROFESSIONAL';
export const DELETE_PROFESSIONAL = 'DELETE_PROFESSIONAL';
export const GET_SINGLE_PROFESSIONAL = 'GET_SINGLE_PROFESSIONAL';
export const UPDATE_PROFESSIONAL = 'UPDATE_PROFESSIONAL';


// LUCKY_DRAW
export const GET_LUCKY_DRAW = 'GET_LUCKY_DRAW';
export const DELETE_LUCKY_DRAW = 'DELETE_LUCKY_DRAW';
export const GET_SINGLE_LUCKY_DRAW = 'GET_SINGLE_LUCKY_DRAW';
export const UPDATE_LUCKY_DRAW = 'UPDATE_LUCKY_DRAW';