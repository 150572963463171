import React from 'react';
import PropTypes from 'prop-types';
// import Header from './Header';
// import Footer from './Footer';

export default function DefaultLayout({ children }) {
  // console.log('props:' props);

  return (
    <>
      {/* <Header /> */}
      {children}
      {/* <Footer /> */}
    </>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
