import axios from "axios";
import { BASE_URL } from "../constants";
import { getAuthToken } from "../auth";

const isHandlerEnabled = (config) => {
  return config.hasOwnProperty("handlerEnabled") && !config.handlerEnabled
    ? false
    : true;
};

const requestHandler = (request) => {
  // console.log('requestHandler', request);
  if (localStorage.getItem("token")) {
    request.headers.Authorization = "Bearer " + getAuthToken();
  }
  return request;
};

const errorHandler = (error) => {
  if (isHandlerEnabled(error.config)) {
    // console.log('errorHandler', error)
    const err = error.response;
    if (err.status === 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }
  }

  return Promise.reject({ ...error });
};

const successHandler = (response) => {
  if (isHandlerEnabled(response.config)) {
    // console.log('successHandler', response)
  }
  return response;
};

export const axiosInstance = axios.create({
  baseURL: BASE_URL,
});
axiosInstance.interceptors.request.use((request) => requestHandler(request));

axiosInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);
